import _ from 'lodash';
import styled from 'styled-components';
import Toast from 'react-bootstrap/Toast';
import React from 'react';
import './signup.scss';
import PropTypes from 'prop-types';
import AcademyName from '../components/utils/AcademyName';

export const validateNonEmptyString = (name) => {
  return !_.isEmpty(name);
};

export const validateEmail = (email) => {
  const mailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return mailReg.test(email);
};

export const FormOuterWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 5px;
  padding: 5px;
`;

export function ValidationToast(props) {
  const liArray = [];
  props.fieldValidationError.forEach((currentError) => {
    liArray.push(<li className="li-validation-error"
                     key={currentError}>{currentError}</li>);
  });
  return (
      <Toast onClose={() => props.setShowToast(false)}>
        <Toast.Header>
          <strong className="mr-auto">Validation error occurred!</strong>
          <small>:(</small>
        </Toast.Header>
        <Toast.Body>
          <ul>{liArray}</ul>
        </Toast.Body>
      </Toast>
  );
}

ValidationToast.propTypes = {
  fieldValidationError: PropTypes.array,
  setShowToast: PropTypes.func
};
