import React from 'react';
import PropTypes from 'prop-types';
import './academyName.scss';

function AcademyName(props) {
  return (
      <div className="outer-academy-name-wrapper">
          <div className="inner-academy-name-wrapper">Decent Academy of Computer Science</div>
          <div className="inner-academy-body-wrapper">Redefining Future</div>
      </div>
  );
}

AcademyName.propTypes = {
  show: PropTypes.bool,
  time: PropTypes.number
}

export default AcademyName;