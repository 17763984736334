import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MDBIcon } from 'mdbreact';
import _ from 'lodash';
import { setHistoryForCourse } from './courseUtils';
import { AccordionBody, AccordionHeader, AccordionHeaderWrapper } from './courseJSXUtils';

function CourseNavigationPanel(props) {
    const { expandedCourses, history } = props;

    const [openedCourseIds, setOpenedCourseIds] = useState(new Set());

    return expandedCourses.map((currentCourse) => {

        const chapters = <AllChapters
            courseId={currentCourse.courseId}
            history={history}
            expandedChapters={currentCourse.expandedChapters}
        />;
        const isExpanded = openedCourseIds.has(currentCourse.courseId);
        const iconBeforeCourse = isExpanded ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />;
        return (
            <CourseWrapper key={currentCourse.courseId}>
                <Accordion>
                    <AccordionHeaderWrapper>
                        <Accordion.Toggle as={AccordionHeader} variant="link" eventKey="0" onClick={() => {
                            setHistoryForCourse(currentCourse.courseId, undefined, undefined, history);
                            if (isExpanded) {
                                const newSet = new Set(openedCourseIds);
                                newSet.delete(currentCourse.courseId);
                                setOpenedCourseIds(newSet);
                            } else {
                                const newSet = new Set(openedCourseIds);
                                newSet.add(currentCourse.courseId);
                                setOpenedCourseIds(newSet);
                            }
                        }}>
                            {iconBeforeCourse}&nbsp;&nbsp;
                            {currentCourse.title}
                        </Accordion.Toggle>
                    </AccordionHeaderWrapper>
                    <Accordion.Collapse eventKey="0">
                        <AccordionBody>{chapters}</AccordionBody>
                    </Accordion.Collapse>
                </Accordion>
            </CourseWrapper>
        );
    });

}

function AllChapters(props) {
    const { expandedChapters, courseId, history } = props;

    return expandedChapters.map((currentChapter) => {

        const [openedChapterIds, setOpenedChapterIds] = useState(new Set());

        const topics = currentChapter.topics.map((currentTopic) => {
            return <li style={{ cursor: 'pointer' }} key={currentTopic.topicId} onClick={() => {
                setHistoryForCourse(courseId, currentChapter.chapterId, currentTopic.topicId, history);
            }}>{currentTopic.title}</li>;
        });

        const isExpanded = openedChapterIds.has(currentChapter.chapterId);
        const iconBeforeCourse = isExpanded ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />;

        return (
            <div key={currentChapter.chapterId}>
                <Accordion>
                    <AccordionHeaderWrapper>
                        <Accordion.Toggle as={AccordionHeader} variant="link" eventKey="0" onClick={() => {
                            setHistoryForCourse(courseId, currentChapter.chapterId, undefined, history);
                            if (isExpanded) {
                                const newSet = new Set(openedChapterIds);
                                newSet.delete(currentChapter.chapterId);
                                setOpenedChapterIds(newSet);
                            } else {
                                const newSet = new Set(openedChapterIds);
                                newSet.add(currentChapter.chapterId);
                                setOpenedChapterIds(newSet);
                            }
                        }}>
                            {iconBeforeCourse}&nbsp;&nbsp;
                            {currentChapter.title}
                        </Accordion.Toggle>
                    </AccordionHeaderWrapper>
                    <Accordion.Collapse eventKey="0">
                        <AccordionBody><ul style={{marginBottom: '0px'}}>{topics}</ul></AccordionBody>
                    </Accordion.Collapse>
                </Accordion>
            </div>
        );
    });

}

const CourseWrapper = styled.div`
    border: 1px solid #aaaaaa;
    margin: 5px;
`;


CourseNavigationPanel.propTypes = {
    expandedCourses: PropTypes.array,
    history: PropTypes.object
};


export default CourseNavigationPanel;