import {clearLocalStorage} from '../commons/localStorageUtils';
import {Button} from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const Logout = (props) => {
  const logout = () => {
    props.logoutUser();
    clearLocalStorage();
  };
  return (
      <div>
        <div>Welcome {props.user.user.userName}!</div>
        <div><Button onClick={logout}>Logout</Button></div>
      </div>
  );
};

Logout.propTypes = {
  user: PropTypes.object,
  logoutUser: PropTypes.func
};

export default Logout;
