import React, {useState} from 'react';
import hljs from 'highlight.js';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import './customMDEditor.scss';
import PropTypes from 'prop-types';

function CustomMDEditor(props) {

  const [markdownHTML, setMarkdownHTML] = useState('');

  /*
    get the complete option list here:
    https://github.com/Ionaru/easy-markdown-editor#configuration
   */
  return (
      <>
        <b>{props.title}</b>
        <SimpleMDE
            className="custom-md-editor"
            onChange={handleChange}
            value={markdownHTML}
            options={{
              previewImagesInEditor: true,
              lineNumbers: true,
              placeholder: `Enter ${props.title}`,
              uploadImage: true,
              tabSize: 4,
              renderingConfig: {
                codeSyntaxHighlighting: true,
                markedOptions: {
                  gfm: true,
                  tables: true,
                  breaks: true,
                  pedantic: false,
                  sanitize: true,
                  smartLists: true,
                  smartypants: true,
                  langPrefix: 'hljs language-',
                  highlight: function(code) {
                    return hljs.highlightAuto(code,
                        ['html', 'javascript', 'java']).value;
                  },
                },
              },
            }}
        />
      </>
  );

  function handleChange(value) {
    props.handleChange(value);
    setMarkdownHTML(value);
  }

}

CustomMDEditor.propTypes = {
  title: PropTypes.string,
  handleChange: PropTypes.func,
};

export default CustomMDEditor;
