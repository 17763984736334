import React, {useState} from 'react';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import request from 'superagent';
import {MDBCard, MDBCardTitle, MDBContainer, MDBInput} from 'mdbreact';
import CustomProgressBar from '../components/utils/CustomProgressBar';
import Toast from 'react-bootstrap/Toast';
import _ from 'lodash';
import {NETWORK_CALL_STATUS} from '../components/utils/constants';
import AcademyName from '../components/utils/AcademyName';
import './signup.scss';
import {getSetUserActionObject} from '../stateManager/users/actions';
import PropTypes from 'prop-types';
import {
  FormOuterWrapper,
  validateNonEmptyString,
  ValidationToast,
} from './utils';
import {PaddedDiv} from '../components/utils/jsxUtils';
import {isResponseSuccessfulWithSuccessMessage} from '../components/utils/responseUtils';
import {saveUserNameAndPassword} from '../commons/localStorageUtils';
import {getEncodedMessage} from '../commons/SHAUtils';

function Login(props) {

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const [loginStatus, setLoginStatus] = useState(NETWORK_CALL_STATUS.UNDEFINED);

  const [showToast, setShowToast] = useState(true);

  const [fieldValidationError, setFieldValidationError] = useState(undefined);

  function getFieldValidationErrors() {
    const errorArray = [];
    if (!validateNonEmptyString(userName)) {
      errorArray.push('Username cannot be empty');
    }
    if (!validateNonEmptyString(password)) {
      errorArray.push('Password cannot be empty');
    }
    return errorArray;
  }

  function onSubmit(event) {
    setShowToast(true);
    setLoginStatus(NETWORK_CALL_STATUS.UNDEFINED);
    const currentFieldValidationErrors = getFieldValidationErrors();
    if (_.isEmpty(currentFieldValidationErrors)) {
      setFieldValidationError(currentFieldValidationErrors);
      setLoginStatus(NETWORK_CALL_STATUS.PENDING);
      const p = new Promise((resolve, reject) => {
        request.post('https://localhost:8089/users/login').
            set('Content-Type', 'application/json').
            send({
              password,
              userName,
            }).
            end(function(error, result) {
              if (error) {
                setLoginStatus(NETWORK_CALL_STATUS.FAILED);
                reject(error);
              } else {
                setLoginStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
                resolve(result);
              }
            });
      });
      p.then(
          (result) => {
            if (isResponseSuccessfulWithSuccessMessage(result)) {
              setLoginStatus(
                  NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE);
              const user = {
                userName,
                password: getEncodedMessage(password),
              };
              props.setUser(user);
              saveUserNameAndPassword(userName, password);
            } else {
              setLoginStatus(NETWORK_CALL_STATUS.SUCCESSFUL_WITH_ERROR_MESSAGE);
              setFieldValidationError([...result.body.errorMessages]);
            }
          },
          (error) => {
            console.log(error);
          },
      );
    } else {
      setFieldValidationError(currentFieldValidationErrors);
    }
  }

  function setFieldBasedOnEventValue(event, setField) {
    setField(event.target.value);
  }

  return (
      <div className="signup-form-wrapper">
        <MDBContainer className="signup-mdb-container">
          <MDBCard className="card-body signup-mdb-card"
                   style={{width: '32rem', marginTop: '1rem'}}>
            <MDBCardTitle>Login</MDBCardTitle>
            <FormOuterWrapper>
              {(loginStatus !== NETWORK_CALL_STATUS.SUCCESSFUL && loginStatus !== NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE) &&
              getFormActionItems()}
              {showToast && loginStatus === NETWORK_CALL_STATUS.PENDING &&
              <CustomProgressBar show time={100}/>}
              {showToast &&
              (loginStatus === NETWORK_CALL_STATUS.SUCCESSFUL || loginStatus ===
                  NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE) &&
              getToast()}
              {showToast && !_.isEmpty(fieldValidationError) &&
              <ValidationToast fieldValidationError={fieldValidationError}
                               setShowToast={setShowToast}/>}
              {loginStatus === NETWORK_CALL_STATUS.FAILED && getToast()}
            </FormOuterWrapper>
          </MDBCard>
        </MDBContainer>
        <AcademyName/>
      </div>
  );

  function getFormActionItems() {
    return (
        <>
          <MDBInput
              outline
              className={'signup-input-field' +
              (!_.isNil(fieldValidationError) &&
              !validateNonEmptyString(userName) ?
                  ' error-input-field' :
                  '')}
              containerClass="text-left"
              icon="user"
              value={userName}
              label="User name"
              onChange={(event) => setFieldBasedOnEventValue(event,
                  setUserName)}
          />
          <MDBInput
              outline
              className={'signup-input-field' +
              (!_.isNil(fieldValidationError) &&
              !validateNonEmptyString(password) ?
                  ' error-input-field' :
                  '')}
              containerClass="text-left"
              icon="user-secret"
              type="password"
              label="Password"
              value={password}
              onChange={(event) => setFieldBasedOnEventValue(event,
                  setPassword)}
          />
          <PaddedDiv>
            <Button variant="primary" onClick={onSubmit}>LOGIN</Button>
          </PaddedDiv>
        </>
    );
  }

  function getToast() {
    if (loginStatus === NETWORK_CALL_STATUS.FAILED) {
      return (
          <Toast onClose={() => setShowToast(false)}>
            <Toast.Header>
              <strong className="mr-auto">Login failed!&nbsp;&nbsp;</strong>
              <small>:(</small>
            </Toast.Header>
            <Toast.Body>Try again!</Toast.Body>
          </Toast>
      );
    }
    if (loginStatus === NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE) {
      return (
          <Toast onClose={() => setShowToast(false)}>
            <Toast.Header>
              <strong className="mr-auto">Login Successful!&nbsp;&nbsp;</strong>
              <small>:)</small>
            </Toast.Header>
            <Toast.Body>Yipieeee</Toast.Body>
          </Toast>
      );
    }
  }

}

Login.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(getSetUserActionObject(user)),
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
