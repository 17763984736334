import React from 'react';
import { PaddedDiv } from './utils/jsxUtils';
import styled from 'styled-components';
import pamphlet1 from '../images/pamphlet1.png';
import pamphlet2 from '../images/pamphlet2.png';
import PropTypes from 'prop-types';

function AboutUs() {
    return (
        <PaddedDiv>

            <PaddedDiv padding={20} className="grid">
                <div className="row">
                    <LeftWrapper className="col-sm-4 col-md-4">
                        <h3 className="lead"> About our Academy: </h3>
                        <hr />

                        <ParagraphWrapper>
                            We provide expert professional training for Java, Data-Structures, algorithms, design paradigm and interview preparation. These are just a few fields to mention and there&apos;s no doubt that this is in no way an exhaustive list. Also, we provide key facilities for students that enable collaborative learning. <br />
                            Mr. Anil Kumar Bhanawat is the founder and director of Decent Academy. He has good management and interpersonal skills.
                        </ParagraphWrapper>
                        <br />
                        <h4>Our Services: </h4>
                        <hr />
                        
                        <strong> 1. Programming Courses for Seniors </strong> <br /><br />
                        <ParagraphWrapper>
                            Decent Academy offers high-level programming courses like Java, Data Structure, Algorithm and Design patterns with practical-oriented teaching methodology and special practical classes. Our main focus is to make a student a Java-professional so that he/she can stand in MNCs and corporate industry. We provide a very efficient and easily accessible ecosystem to help students and learners by our world-class technical training. We provide training to students who are pursuing engineering in computer science and any other branch so that they learn the latest technologies as per the industry standards.
                        </ParagraphWrapper>
                        <br /><br />
                        
                        <strong> 2. Programming Courses for Juniors </strong> <br /><br />
                        <ParagraphWrapper>
                            We believe in strong technical pillars, therefore our curriculum is not only limited to college students and we also offer coding/programming classes for 6th to 12th standard according to CBSE syllabus.
                        </ParagraphWrapper>
                        <br /><br />

                        <strong> 3. Library </strong> <br /><br />
                        <ParagraphWrapper>
                        Decent Academy also provides a peaceful environment to boost your knowledge. A 24x7 open library and a good environment of study definitely results in success. Therefore, we have planned to offer students a library with good books.
                        </ParagraphWrapper>
                        <br /><br />

                        <strong> 4. Workstation </strong> <br /><br />
                        <ParagraphWrapper>
                        Decent Academy provides a working area for individual professionals as we believe work is a reflection of the person who does it. Much of the work we do now is to benefit another person or persons. Hence our goal of workstation is to help people achieve their dreams.
                        </ParagraphWrapper>
                        <br /><br />

                        <strong> 5. Conference Hall </strong> <br /><br />
                        <ParagraphWrapper>
                        Decent Academy understands the concern for your business and on these lines we provide AC conference hall for singular events on a daily basis. It includes ideal professional space to conduct business meetings, seminars and other activities as per requirement. We not only provide the conference hall but along with that we also provide the facility of a separate cabin, podium and fully equipped computer lab.
                        </ParagraphWrapper>
                        <br /><br />

                        <strong> 6. Special Maths class </strong> <br /><br />
                        <ParagraphWrapper>
                        Mathematics makes our life orderly and prevents chaos. We offer classes for complete syllabus of 10th standard Mathematics (CBSE) to students so they can plan on majoring in STEM fields (science, technology, engineering and math).
                        </ParagraphWrapper>
                        <br /><br />

                        <br />
                    </LeftWrapper>
                    <div className="col-sm-8 col-md-8">
                        <h3 className="lead" id="anchor1"> Java Programming Course </h3>
                        <hr />
                        <h4>What we’ll be covering in this course:</h4>
                        <ul>
                            <li>Intro to Object Oriented concepts with basic principles</li>
                            <li>Core Java language primitives</li>
                            <li>Data-types, variables, arrays and operators</li>
                            <li>Control Statements</li>
                            <li>Classes, objects and methods</li>
                            <li>Enums</li>
                            <li>Interfaces</li>
                            <li>package structure</li>
                            <li>Lambdas and Functional Interfaces</li>
                            <li>Concurrent APIs in Java</li>
                            <li>Stream API in Java</li>
                            <li>Generics</li>
                            <li>Exception Handling</li>
                            <li>Java’s Collection Framework</li>
                            <li>Concurrency and Parallelism</li>
                            <li>Threads</li>
                            <li>Executor Service</li>
                            <li>Fork-join Pool</li>
                        </ul>
                        <hr />
                        <h4>In addition to the above mentioned topics we’ll also be providing a brief introduction to:</h4>
                        <ul>
                            <li>IDE (IntelliJ)</li>
                            <li>Git (Github)</li>
                            <li>Markdown</li>
                        </ul>
                        <br />
                        <br />
                        <PamphletImage pamphlet={pamphlet1} />
                        <PamphletImage pamphlet={pamphlet2} />
                    </div>
                </div>
            </PaddedDiv>

        </PaddedDiv>
    );
}

function PamphletImage(props) {
    return (
        <PamphletWrapper>
            <img style={{ border: '1px solid blue' }} src={props.pamphlet} width={500} height={700} />
        </PamphletWrapper>
    );
}

PamphletImage.propTypes = {
    pamphlet: PropTypes.object
};

const PamphletWrapper = styled.span`
    padding: 10px;
`;

const ParagraphWrapper = styled.p`
    text-align: justify; 
    text-justify: inter-word;
`;

const LeftWrapper = styled.div`
    background: #528C7C;
    box-shadow: 3px grey;
    color: white;
    padding: 30px;
    letter-spacing: .9px;
`;

export default AboutUs;