import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { MDBIcon } from 'mdbreact';
import {
	getUserNameAndPassword,
} from '../commons/localStorageUtils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	getSetUserActionObject,
} from '../stateManager/users/actions';
import styled from 'styled-components';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './navbar.scss';
import { NAVIGATION_TABS } from './utils/constants';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import movingLogo from '../images/movinglogo.png';

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user) => dispatch(getSetUserActionObject(user))
	};
};


function NavigationBar(props) {
	useEffect(() => {
		if (_.isNil(props.user.user)) {
			const userFromLocalStorage = getUserNameAndPassword();
			if (_.isNil(userFromLocalStorage.password) || _.isNil(userFromLocalStorage.userName)) {
				// then the user is not logged in
			} else {
				props.setUser(userFromLocalStorage);
			}
		}
	});
	const { history } = props;
	let selectedTabName = NAVIGATION_TABS.home.name;
	if (history.location.pathname === '/courses') {
		selectedTabName = NAVIGATION_TABS.courses.name;
	} else if (history.location.pathname === '/about') {
		selectedTabName = NAVIGATION_TABS.aboutUs.name;
	} else if (history.location.pathname === '/team') {
		selectedTabName = NAVIGATION_TABS.team.name;
	} else if (history.location.pathname === NAVIGATION_TABS.admin.url) {
		selectedTabName = NAVIGATION_TABS.admin.name;
	}
	const [selectedNavTab, setSelectedNavTab] = useState(selectedTabName);
	const [keyCounter, setKeyCounter] = useState(0);

	return (
		<>
			
			<div className="navbar125">
			<Link
				to="/home" 
				onClick={
					() => {
						setSelectedNavTab(NAVIGATION_TABS.home.name);
						setKeyCounter(keyCounter + 1);
					}
				}
				className="moving-logo"
			>
				<img
					key={keyCounter}
					className="office-logo animated fadeInDownBig"
					src={movingLogo}
					style={{ maxWidth: '560px', maxHeight: '100px !important' }}
				/>
			</Link>
				<div style={{ float: 'right' }}>
					<Link to="/">
						<LinkWrapper
							isSelected={selectedNavTab === NAVIGATION_TABS.home.name}
							className="rounded"
							onClick={
								() => {
									setSelectedNavTab(NAVIGATION_TABS.home.name);
									setKeyCounter(keyCounter + 1);
								}
							}
						>
							{getItem(NAVIGATION_TABS.home.name, NAVIGATION_TABS.home.iconName)}
						</LinkWrapper>
					</Link>
					<Link to="/courses">
						<LinkWrapper
							isSelected={selectedNavTab === NAVIGATION_TABS.courses.name}
							className="rounded"
							onClick={
								() => {
									setSelectedNavTab(NAVIGATION_TABS.courses.name);
									setKeyCounter(keyCounter + 1);
								}
							}
						>
							{getItem(NAVIGATION_TABS.courses.name, NAVIGATION_TABS.courses.iconName, true)}
						</LinkWrapper>
					</Link>
					<Link to="/about">
						<LinkWrapper
							isSelected={selectedNavTab === NAVIGATION_TABS.aboutUs.name}
							className="rounded"
							onClick={
								() => {
									setSelectedNavTab(NAVIGATION_TABS.aboutUs.name);
									setKeyCounter(keyCounter + 1);
								}
							}
						>
							{getItem(NAVIGATION_TABS.aboutUs.name, NAVIGATION_TABS.aboutUs.iconName)}
						</LinkWrapper>
					</Link>
					<Link to="/team">
						<LinkWrapper
							isSelected={selectedNavTab === NAVIGATION_TABS.team.name}
							className="rounded"
							onClick={
								() => {
									setSelectedNavTab(NAVIGATION_TABS.team.name);
									setKeyCounter(keyCounter + 1);
								}
							}
						>
							{getItem(NAVIGATION_TABS.team.name, NAVIGATION_TABS.team.iconName)}
						</LinkWrapper>
					</Link>
					<Link to="/admin">
						<LinkWrapper
							isSelected={selectedNavTab === NAVIGATION_TABS.admin.name}
							className="rounded"
							onClick={
								() => {
									setSelectedNavTab(NAVIGATION_TABS.admin.name);
									setKeyCounter(keyCounter + 1);
								}
							}
						>
							{getItem(NAVIGATION_TABS.admin.name, NAVIGATION_TABS.admin.iconName)}
						</LinkWrapper>
					</Link>
					<Link className="phone-number">
						<PhoneNumberWrapper>
							{getItem('+91-8003893172', 'phone-volume')}
						</PhoneNumberWrapper>
					</Link>
					{getLoginSignupItems()}
				</div>

			</div>
		</>
	);

	function getLoginSignupItems() {
		if (_.isNil(props.user?.user?.userName)) {
			return <React.Fragment />;
		}
		return (
			<>
				<Link to="/login">
					<LinkWrapper
						isSelected={selectedNavTab === NAVIGATION_TABS.login.name}
						className="rounded"
						onClick={() => setSelectedNavTab(NAVIGATION_TABS.login.name)}
					>
						{getItem(NAVIGATION_TABS.login.name, NAVIGATION_TABS.login.iconName)}
					</LinkWrapper>
				</Link>
				<Link to="/signup">
					<LinkWrapper
						isSelected={selectedNavTab === NAVIGATION_TABS.signUp.name}
						className="rounded"
						onClick={() => setSelectedNavTab(NAVIGATION_TABS.signUp.name)}
					>
						{getItem(NAVIGATION_TABS.signUp.name, NAVIGATION_TABS.signUp.iconName)}
					</LinkWrapper>
				</Link>
			</>
		);
	}

	function getItem(label, iconName, spin = false) {
		return (
			<div>
				<MDBIcon
					className="indigo-text"
					icon={iconName}
					size="2x"
					spin={spin}
				/>
         
				<NavTabLabelWrapper>
					<b>&nbsp;{label}</b>
				</NavTabLabelWrapper>
			</div>
		);
	}

}

const NavTabLabelWrapper = styled.span`
	font-size: 20px;
	color: #777777;
	vertical-align: 4px;
	@media (max-width: 800px) {
		display: none;
	}
`;

const PhoneNumberWrapper = styled.div`
	float: left;
	display: block;
	padding: 15px 10px 10px 10px;
`;

const LinkWrapper = styled.div`
	border: 3px ridge blue;
  	float: left;
	display: block;
	color: #f2f2f2;
	text-align: center;
	padding: 10px 10px 10px 10px;
	text-decoration: none;
	margin: 5px 5px 5px 5px;
	background: ${(props) => props.isSelected ? 'rgba(76, 175, 80, 0.2)' : 'rgba(76, 175, 80, 0.0)'};
	border: ${(props) => props.isSelected ? '1px ridge blue' : 'none'};
	&:hover {
		background: rgba(76, 175, 80, 0.2);
	}
`;

NavigationBar.propTypes = {
	user: PropTypes.object,
	setUser: PropTypes.func,
	history: PropTypes.object
};

export default compose(
	withRouter, connect(
		mapStateToProps,
		mapDispatchToProps,
	)
)(NavigationBar);