import React, {useEffect, useState} from 'react';
import {PaddedDiv} from '../utils/jsxUtils';
import Select from 'react-select';
import {MDBInput} from 'mdbreact';
import CustomMDEditor from '../utils/mdEditor/CustomMDEditor';
import Button from 'react-bootstrap/Button';
import {NETWORK_CALL_STATUS} from '../utils/constants';
import _ from 'lodash';
import request from 'superagent';
import {validateNonEmptyString} from '../../login/utils';
import Toast from 'react-bootstrap/Toast';
import {setChapters, setCourses} from '../../stateManager/courses/action';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

function AddTopicEditor(props) {
  const [title, setTitle] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(undefined);
  const [selectedChapter, setSelectedChapter] = useState(undefined);
  const [content, setContent] = useState('');

  const [keyCounter, setKeyCounter] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [publishStatus, setPublishStatus] = useState(
      NETWORK_CALL_STATUS.UNDEFINED,
  );
  const [pageLoadStatus, setPageLoadStatus] = useState(
      NETWORK_CALL_STATUS.UNDEFINED,
  );
  const [fieldValidationError, setFieldValidationError] = useState([]);

  useEffect(() => {
    if (_.isNil(props.courses?.courses) || _.isNil(props.courses?.chapters)) {
      let courseFetchStatus = NETWORK_CALL_STATUS.UNDEFINED;
      let chapterFetchStatus = NETWORK_CALL_STATUS.UNDEFINED;

      const p = new Promise((resolve, reject) => {
        request.get('https://localhost:8089/courses/getAllCourses').
            end(function(error, result) {
              if (error) {
                courseFetchStatus = NETWORK_CALL_STATUS.FAILED;
                reject(error);
              } else {
                courseFetchStatus = NETWORK_CALL_STATUS.SUCCESSFUL;
                resolve(result);
              }
            });
      });
      const p1 = new Promise((resolve, reject) => {
        request.get('https://localhost:8089/chapters/getAllChapters').
            end(function(error, result) {
              if (error) {
                chapterFetchStatus = NETWORK_CALL_STATUS.FAILED;
                reject(error);
              } else {
                chapterFetchStatus = NETWORK_CALL_STATUS.SUCCESSFUL;
                resolve(result);
              }
            });
      });

      p.then(
          (result) => {
            const courses = result.body;
            props.setCourses(courses);
          },
          (error) => {
            console.log(error);
          },
      );
      p1.then(
          (result) => {
            const chapters = result.body;
            props.setChapters(chapters);
          },
          (error) => {
            console.log(error);
          },
      );
      if (chapterFetchStatus === NETWORK_CALL_STATUS.SUCCESSFUL &&
          courseFetchStatus === NETWORK_CALL_STATUS.SUCCESSFUL) {
        setPageLoadStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
      } else {
        setPageLoadStatus(NETWORK_CALL_STATUS.FAILED);
      }
    } else {
      setPageLoadStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
    }
  }, []);

  if (_.isNil(props.courses?.courses) || pageLoadStatus ===
      NETWORK_CALL_STATUS.UNDEFINED) {
    return <React.Fragment/>;
  }

  const courseOptions = getCourseOptions();
  const chapterOptions = getChapterOptions();
  return (
      <PaddedDiv padding={10}>
        {getEditorContents()}
        {getToast()}
      </PaddedDiv>
  );

  function getEditorContents() {
    if (publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL) {
      return <React.Fragment/>;
    }
    return (
        <>
          <h4>Add Topic</h4>
          <b>Select Course</b>
          <Select
              key={keyCounter.toString() + '-select-course'}
              options={courseOptions}
              value={selectedCourse}
              onChange={handleCourseChange}
          />
          <b>Select Chapter</b>
          <Select
              key={keyCounter.toString() + '-select-chapter'}
              options={chapterOptions}
              value={selectedChapter}
              onChange={handleChapterChange}
          />
          <MDBInput
              outline
              containerClass="text-left"
              icon="book"
              value={title}
              label="Topic Title"
              onChange={(event) => setTitle(event.target.value)}
          />
          <CustomMDEditor
              key={keyCounter.toString() + '-mdeditor'}
              title="Topic Contents"
              handleChange={(value) => setContent(value)}
          />
          <Button variant="success" onClick={publishChapterHandler}>
            Publish Topic
          </Button>
          <Button variant="danger" onClick={clearCourseHandler}>
            Clear Contents
          </Button>
        </>
    );
  }

  function handleCourseChange(selectedCourse) {
    setSelectedCourse(selectedCourse);
  }

  function handleChapterChange(selectedChapter) {
    setSelectedChapter(selectedChapter);
  }

  function getCourseOptions() {
    if (_.isEmpty(props.courses?.courses)) {
      return [];
    }
    const retValue = props.courses.courses.map((currentCourse) => {
      return {
        ...currentCourse,
        label: currentCourse.title,
        value: currentCourse.title,
      };
    });
    return _.orderBy(retValue, ['label'], ['asc']);
  }

  function getChapterOptions() {
    if (_.isEmpty(props.courses?.courses) ||
        _.isEmpty(props.courses?.chapters)
    ) {
      return [];
    }
    const retValue = props.courses.chapters.filter((currentChapter) => {
      return currentChapter.courseId === selectedCourse?.courseId;
    }).map((curretnChapter) => {
      return {
        ...curretnChapter,
        label: curretnChapter.title,
        value: curretnChapter.title,
      };
    });
    return _.orderBy(retValue, ['label'], ['asc']);
  }

  function publishChapterHandler() {
    setShowToast(true);
    setPublishStatus(NETWORK_CALL_STATUS.UNDEFINED);
    const currentFieldValidationErrors = getFieldValidationErrors();
    if (_.isEmpty(currentFieldValidationErrors)) {
      setFieldValidationError(currentFieldValidationErrors);
      setPublishStatus(NETWORK_CALL_STATUS.PENDING);

      const p = new Promise((resolve, reject) => {
        request.post('https://localhost:8089/topics/createTopic').
            set('Content-Type', 'application/json').
            send({
              courseId: selectedCourse.courseId,
              chapterId: selectedChapter.chapterId,
              title,
              content,
            }).
            end(function(error, result) {
              if (error) {
                setPublishStatus(NETWORK_CALL_STATUS.FAILED);
                reject(error);
              } else {
                setPublishStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
                resolve(result);
              }
            });
      });
      p.then(
          (result) => {

          },
          (error) => {

          },
      );
    } else {
      setFieldValidationError(currentFieldValidationErrors);
    }
  }

  function getFieldValidationErrors() {
    const errorArray = [];
    if (_.isNil(selectedCourse)) {
      errorArray.push('Select a course');
    }
    if (_.isNil(selectedChapter)) {
      errorArray.push('Select a chapter');
    }
    if (!validateNonEmptyString(title)) {
      errorArray.push('Topic title cannot be empty');
    }
    return errorArray;
  }

  function clearCourseHandler() {
    setTitle('');
    setSelectedCourse(undefined);
    setSelectedChapter(undefined);
    setKeyCounter(keyCounter + 1);
    setShowToast(false);
  }

  function getToast() {
    if (!showToast) {
      return <React.Fragment/>;
    }

    let headerString;
    let toastBody;
    if (publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL) {
      headerString = 'Successfully published the chapter :)';
      toastBody = 'Enjoy!!!';
    } else if (
        publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL_WITH_ERROR_MESSAGE ||
        publishStatus === NETWORK_CALL_STATUS.UNDEFINED
    ) {
      headerString = 'Error when publishing the chapter :(';
      const liArray = [];
      fieldValidationError.forEach((currentError) => {
        liArray.push(<li className="li-validation-error"
                         key={currentError}>{currentError}</li>);
      });
      toastBody = <ul>{liArray}</ul>;
    }

    return (
        <Toast onClose={() => setShowToast(false)}>
          <Toast.Header>
            <strong className="mr-auto">{headerString}</strong>
          </Toast.Header>
          <Toast.Body>{toastBody}</Toast.Body>
        </Toast>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCourses: (courses) => dispatch(setCourses(courses)),
    setChapters: (chapters) => dispatch(setChapters(chapters)),
  };
};

AddTopicEditor.propTypes = {
  setCourses: PropTypes.func,
  setChapters: PropTypes.func,
  courses: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddTopicEditor);