import React from 'react';
import AddCourseComponent from './course/AddCourseComponent';

function AdminPanel() {

    return (
        <AddCourseComponent />
    );

}

export default AdminPanel;