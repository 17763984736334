import { getEncodedMessage } from './SHAUtils';
import _ from 'lodash';

export const USER_NAME = 'USER_NAME';

export const PASSWORD = 'PASSWORD';

export function saveUserNameAndPassword(userName, password) {
	localStorage.setItem(USER_NAME, userName);
	const encodedPassword = getEncodedMessage(password);
	localStorage.setItem(PASSWORD, encodedPassword);
}

export function getUserNameAndPassword() {
	const userName = localStorage.getItem(USER_NAME);
	const password = localStorage.getItem(PASSWORD);
	return {
		userName,
		password,
	};
}

export function clearLocalStorage() {
	localStorage.clear();
}

export function isUserLoggedIn() {
	const user = getUserNameAndPassword();
	return !_.isNil(user.userName) && !_.isNil(user.password);
}
