const initialState = {
    user: undefined,
}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
    case 'users/setUser': {
        return {
            ...state,
            user: action.payload.user
        }
    }
    case 'users/logout' : {
        return {
            ...state,
            user: undefined
        }
    }
    default:
        return state
    }
}