export const NETWORK_CALL_STATUS = {
	UNDEFINED: 'UNDEFINED',
	PENDING: 'PENDING',
	SUCCESSFUL: 'SUCCESSFUL',
	SUCCESSFUL_WITH_SUCCESS_MESSAGE: 'SUCCESSFUL_WITH_SUCCESS_MESSAGE',
	SUCCESSFUL_WITH_ERROR_MESSAGE: 'SUCCESSFUL_WITH_ERROR_MESSAGE',
	FAILED: 'FAILED'
};

export const NAVIGATION_TABS = {
	home: {
		name: 'Home', 
		iconName: 'home',
		url: '/home'
	},
	courses: {
		name: 'Courses',
		iconName:'dharmachakra',
		url: '/courses'
	},
	aboutUs: {
		name: 'About Us',
		iconName: 'address-book',
		url: '/about'
	},
	team: {
		name: 'Our Team',
		iconName: 'users',
		url: '/team'
	},
	login: {
		name: 'Login',
		iconName: 'user-check',
		url: '/login'
	},
	signUp: {
		name: 'Sign Up',
		iconName: 'user-plus',
		url: '/signup'
	},
	admin: {
		name: 'Admin Panel',
		iconName: 'user-ninja',
		url: '/admin'
	}
}
