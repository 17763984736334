import _ from 'lodash';

export function setHistoryForCourse(selectedCourseId, selectedChapterId, selectedTopicId, history) {

    let searchObject = {};
    if (!_.isNil(selectedCourseId)) {
        searchObject = {
            courseId: selectedCourseId
        };
        if (!_.isNil(selectedChapterId)) {
            searchObject = {
                ...searchObject,
                chapterId: selectedChapterId
            };
            if (!_.isNil(selectedTopicId)) {
                searchObject = {
                    ...searchObject,
                    topicId: selectedTopicId
                };
            }
        }
    }

    const searchString = _.isEmpty(searchObject) ? undefined : ('?' + new URLSearchParams(searchObject).toString());

    history.push({
        pathname: '/courses',
        search: searchString
    })
}

export function getParamFromHistoryObject(history, paramName) {
    const search = history.location.search;
    const params = new URLSearchParams(search);
    return params.get(paramName);
}
