const initialState = {
  courses: undefined,
  chapters: undefined
}

export default function coursesReducer(state = initialState, action) {
  switch (action.type) {
    case 'courses/setCourses': {
      return {
        ...state,
        courses: action.payload.courses
      }
    }
    case 'courses/setChapters': {
      return {
        ...state,
        chapters: action.payload.chapters
      }
    }
    default:
      return state
  }
}