import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Home from "../components/home/Home";
import AboutUs from "../components/AboutUs";
import Login from "../login/Login";
import SignUp from "../login/Signup";
import BaseComponent from "../components/BaseComponent";
import Courses from "../components/course/presenter/Courses";
import OurTeam from "../components/OurTeam";
import AdminPanel from "../components/AdminPanel";

function BasicRoutes() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <BaseComponent component={<Home />} />
                </Route>
                <Route exact path="/home">
                    <BaseComponent component={<Home />} />
                </Route>
                <Route path="/about">
                    <BaseComponent component={<AboutUs />} />
                </Route>
                <Route path="/login">
                    <BaseComponent component={<Login />} />
                </Route>
                <Route path="/signup">
                    <BaseComponent component={<SignUp />} />
                </Route>
                <Route path="/courses">
                    <BaseComponent component={<Courses />} />
                </Route>
                <Route path="/team">
                    <BaseComponent component={<OurTeam />} />
                </Route>
                <Route path="/admin">
                    <BaseComponent component={<AdminPanel />} />
                </Route>
            </Switch>
        </Router>
    );
}

export default BasicRoutes;
