import styled from 'styled-components';

export const AccordionHeaderWrapper = styled.div`
    padding: 5px;
    background-color: white;
`;

export const AccordionHeader = styled.span`
    padding: 5px 0px 0px 10px;
    cursor: pointer;
`;

export const AccordionBody = styled.div`
    padding: 5px;
    margin-left: 25px;
    background-color: white;
`;
