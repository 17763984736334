import React from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import styled from 'styled-components';
import './contentPresenter.scss'
import { MDBIcon } from 'mdbreact';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import _ from 'lodash';
import { MDBCard, MDBCardHeader, MDBCardBody } from "mdbreact";
import { setMarkedOptions } from '../../utils/mdEditor/mdUtils';

function ContentPresenter(props) {

    setMarkedOptions(marked);
    const handle = useFullScreenHandle();

    const markdownHTML = marked(props.markdownContent || '');

    return (
        <div style={{ width: "100%" }}>
            <MDBCard >
                <MDBCardHeader>{getButtons()}</MDBCardHeader>
                <MDBCardBody>
                    <FullScreen handle={handle}>
                        <ContentWrapper className="content-presenter" dangerouslySetInnerHTML={{ __html: markdownHTML }} />
                    </FullScreen>
                </MDBCardBody>
            </MDBCard>
        </div>
    );

    function getButtons() {
        if (_.isEmpty(markdownHTML)) {
            return <React.Fragment />;
        }
        const popover = (
            <Popover>
                <Popover.Content>
                    Click to enter full screen mode
              </Popover.Content>
            </Popover>
        );
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 0, hide: 0 }}
                overlay={popover}
                trigger="hover"
            >
                <span style={{ cursor: 'pointer' }} onClick={handle.enter}>
                    <b style={{ verticalAlign: '5px', padding: '10px' }}>Actions:&nbsp;&nbsp;</b>
                    <MDBIcon icon="stroopwafel" spin size="2x" />
                </span>
            </OverlayTrigger>
        );
    }

}

const ContentWrapper = styled.div`
    overflow-y: auto;
    text-align: justify;
`;

ContentPresenter.propTypes = {
    markdownContent: PropTypes.string
}

export default ContentPresenter;