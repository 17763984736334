import React, {useState} from 'react';
import CustomMDEditor from '../utils/mdEditor/CustomMDEditor';
import {PaddedDiv} from '../utils/jsxUtils';
import {MDBInput} from 'mdbreact';
import Button from 'react-bootstrap/Button';
import PropTypes, {func} from 'prop-types';
import {NETWORK_CALL_STATUS} from '../utils/constants';
import _ from 'lodash';
import request from 'superagent';
import {isResponseSuccessfulWithSuccessMessage} from '../utils/responseUtils';
import {validateEmail, validateNonEmptyString} from '../../login/utils';
import Toast from 'react-bootstrap/Toast';
import {setCourses} from '../../stateManager/courses/action';
import {connect} from 'react-redux';

function AddCourseEditor(props) {

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [introduction, setIntroduction] = useState('');

  // to handle the clear button
  const [keyCounter, setKeyCounter] = useState(0);

  const [showToast, setShowToast] = useState(false);
  const [publishStatus, setPublishStatus] = useState(
      NETWORK_CALL_STATUS.UNDEFINED,
  );

  const [fieldValidationError, setFieldValidationError] = useState([]);

  return (
      <PaddedDiv padding={15}>
        {getEditorContents()}
        {getToast()}
      </PaddedDiv>
  );

  function getEditorContents() {
    if (publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE) {
      return <React.Fragment/>;
    }
    return (
        <div>
          <h4>Add Course</h4>
          <MDBInput
              outline
              containerClass="text-left"
              icon="book"
              value={title}
              label="Course Title"
              onChange={(event) => setTitle(event.target.value)}
          />
          <CustomMDEditor
              key={keyCounter.toString()}
              title="Description"
              handleChange={handleDescriptionChange}
          />
          <CustomMDEditor
              key={(keyCounter + 1000).toString()}
              title="Introduction"
              handleChange={handleIntroductionChange}
          />
          <Button variant="success" onClick={publishCourseHandler}>
            Publish Course
          </Button>
          <Button variant="danger" onClick={clearCourseHandler}>
            Clear Contents
          </Button>
        </div>
    );
  }

  function getToast() {
    if (!showToast) {
      return <React.Fragment/>;
    }

    let headerString;
    let toastBody;
    if (publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE) {
      headerString = 'Successfully published the course :)';
      toastBody = 'Enjoy!!!';
    } else if (
        publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL_WITH_ERROR_MESSAGE ||
        publishStatus === NETWORK_CALL_STATUS.UNDEFINED
    ) {
      headerString = 'Error when publishing the course :(';
      const liArray = [];
      fieldValidationError.forEach((currentError) => {
        liArray.push(<li className="li-validation-error"
                         key={currentError}>{currentError}</li>);
      });
      toastBody = <ul>{liArray}</ul>;
    }

    return (
        <Toast onClose={() => setShowToast(false)}>
          <Toast.Header>
            <strong className="mr-auto">{headerString}</strong>
          </Toast.Header>
          <Toast.Body>{toastBody}</Toast.Body>
        </Toast>
    );
  }

  function handleDescriptionChange(value) {
    setDescription(value);
  }

  function handleIntroductionChange(value) {
    setIntroduction(value);
  }

  function publishCourseHandler() {
    setShowToast(true);
    setPublishStatus(NETWORK_CALL_STATUS.UNDEFINED);
    const currentFieldValidationErrors = getFieldValidationErrors();
    if (_.isEmpty(currentFieldValidationErrors)) {
      setFieldValidationError(currentFieldValidationErrors);
      setPublishStatus(NETWORK_CALL_STATUS.PENDING);

      const p = new Promise((resolve, reject) => {
        request.post('https://localhost:8089/courses/createCourse').
            set('Content-Type', 'application/json').
            send({
              title,
              description,
              introduction,
            }).
            end(function(error, result) {
              if (error) {
                setPublishStatus(NETWORK_CALL_STATUS.FAILED);
                reject(error);
              } else {
                setPublishStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
                props.setCourses(undefined);
                resolve(result);
              }
            });
      });
      p.then(
          (result) => {
            if (isResponseSuccessfulWithSuccessMessage(result)) {
              setPublishStatus(
                  NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE);
            } else {
              setPublishStatus(
                  NETWORK_CALL_STATUS.SUCCESSFUL_WITH_ERROR_MESSAGE);
              setFieldValidationError([...result.body.errorMessages]);
            }
          },
          (error) => {

          },
      );
    } else {
      setFieldValidationError(currentFieldValidationErrors);
    }
  }

  function getFieldValidationErrors() {
    const errorArray = [];
    if (!validateNonEmptyString(title)) {
      errorArray.push('Course title cannot be empty');
    }
    return errorArray;
  }

  function clearCourseHandler() {
    setTitle('');
    setKeyCounter(keyCounter + 1);
    setShowToast(false);
  }

}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCourses: (courses) => dispatch(setCourses(courses)),
  };
};

AddCourseEditor.propTypes = {
  setCourses: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddCourseEditor);