import React from 'react';
import { PaddedDiv } from '../../utils/jsxUtils';
import { courseData } from '../../utils/courseData';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import CourseDetailsPanel from './CourseDetailsPanel';
import CourseNavigationPanel from './CourseNavigationPanel';
import RSC from "react-scrollbars-custom";
import ResizePanel from "react-resize-panel";

function Courses(props) {
    const { expandedCourses, flatCourseData } = courseData;
    return (
        <PaddedDiv>
            <OuterCoursePanel>
                <ResizePanel style={{width: '25%'}} direction="e">
                    <RSC style={{ width: '100%', height: '90vh' }}>
                        <CourseNavigationPanel history={props.history} expandedCourses={expandedCourses} />
                    </RSC>
                </ResizePanel>
                <RSC style={{ width: '100%', height: '90vh' }}>
                    <CourseDetailsPanel history={props.history} expandedCourses={expandedCourses} flatCourseData={flatCourseData} />
                </RSC>
            </OuterCoursePanel>

        </PaddedDiv>
    );

    function getStaticData() {
        return (
            <div>
                <h4>We offer many courses</h4>
                <ul>
                    <li>Programming Courses</li>
                    <ul>
                        <li>
                            Java
					</li>
                        <li>Data Structures</li>
                        <li>Algorithms</li>
                    </ul>
                </ul>
            </div>
        );
    }
}

const OuterCoursePanel = styled.div`
    display: flex;
    border: 1px solid #dddddd;
    background-color: white;
`;

Courses.propTypes = {
    history: PropTypes.object
}

export default withRouter(Courses);