import React from 'react';
import { PaddedDiv } from './utils/jsxUtils';
import PropTypes from 'prop-types';
import anil from '../images/anil.PNG';
import charul from '../images/charul.jpg';
import rakshit from '../images/rakshit.jpg';
import styled from 'styled-components';

function OurTeam() {
    return (
        <PaddedDiv padding={10}>
            <div id="banners"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <PaddedDiv padding={20} className="blockquote-box blockquote-info animated wow fadeInLeft clearfix">
                            <ImageWrapper src={anil} />
                            <h4>
                                Anil Kumar Bhanawat
                            </h4>
                            <p>
                                <TitleWrapper>Founder and Managing Director</TitleWrapper>
                            </p>
                        </PaddedDiv>


                        <PaddedDiv padding={20} className="blockquote-box blockquote-primary animated wow fadeInLeft clearfix">
                        <ImageWrapper src={rakshit} />
                            <h4>
                                Rakshit Kothari
                            </h4>
                            <p>
                                <TitleWrapper>Chief Technology Officer (CTO)</TitleWrapper>
                            </p>
                        </PaddedDiv>
                    </div>

                    <div className="col-md-6">
                        <PaddedDiv padding={20} className="blockquote-box blockquote-danger animated wow fadeInRight clearfix">
                        <ImageWrapper src={charul} />
                            <h4>
                                Charul Bhanawat
                            </h4>
                            <p>
                                <TitleWrapper>Chief Executive Officer (CEO)</TitleWrapper>
                            </p>
                        </PaddedDiv>
                    </div>
                </div>
            </div>
        </PaddedDiv>
    );
}

function ImageWrapper(props) {
    return (
        <div className="square pull-left">
            <img
                src={props.src}
                alt="Feature-img"
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50%',
                    borderRadius: '50%',
                    width: '200px',
                    height: '200px'
                }}
            />
        </div>
    );
}

ImageWrapper.propTypes = {
    src: PropTypes.string
}

const TitleWrapper = styled.b`
    color: #666666;
`;

export default OurTeam;