export function setCourses(courses) {
  return {
    type: 'courses/setCourses',
    payload: {
      courses
    }
  };
}

export function setChapters(chapters) {
  return {
    type: 'courses/setChapters',
    payload: {
      chapters
    }
  };
}

