import React, { useState } from 'react';
import { getParamFromHistoryObject } from './courseUtils';
import { PaddedDiv } from '../../utils/jsxUtils';
import _ from 'lodash';
import Accordion from 'react-bootstrap/Accordion'
import PropTypes from 'prop-types';
import { MDBIcon } from 'mdbreact';
import { AccordionBody, AccordionHeader, AccordionHeaderWrapper } from './courseJSXUtils';
import ContentPresenter from './ContentPresenter';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'

function CourseDetailsPanel(props) {
    const { expandedCourses, flatCourseData, history } = props;
    const tempCourseId = getParamFromHistoryObject(history, 'courseId');
    const tempChapterId = getParamFromHistoryObject(history, 'chapterId');
    const tempTopicId = getParamFromHistoryObject(history, 'topicId');

    const selectedCourseId = _.isNil(tempCourseId) ? undefined : _.toNumber(tempCourseId);
    const selectedChapterId = _.isNil(tempChapterId) ? undefined : _.toNumber(tempChapterId);
    const selectedTopicId = _.isNil(tempTopicId) ? undefined : _.toNumber(tempTopicId);

    const selectedCourse = flatCourseData.courses.find(currentCourse => {
        return currentCourse.courseId === selectedCourseId;
    });
    const selectedChapter = flatCourseData.chapters.find(currentChapter => {
        return currentChapter.chapterId === selectedChapterId;
    });
    const selectedTopic = flatCourseData.topics.find(currentTopic => {
        return currentTopic.topicId === selectedTopicId;
    });

    if (_.isNil(selectedCourseId) && _.isNil(selectedChapterId) && _.isNil(selectedTopicId)) {
        return (
            <InfoIcon key="info-icon" />
        );
    }
    const contents = [
        <InfoIcon key="info-icon" />,
        <CourseCard course={selectedCourse} key='courseCard' />
    ];
    if (!_.isNil(selectedChapter)) {
        contents.push(<ChapterCard chapter={selectedChapter} key='chapterCard' />);
        if (!_.isNil(selectedTopic)) {
            contents.push(<TopicCard topic={selectedTopic} key='topicCard' />);
        }
    }

    return contents;

}

function InfoIcon() {
    const defaultMessage = "Select Course > Chapter > topic to get details in this panel";
    const popover = (
        <Popover>
            <Popover.Content>
                <b>{defaultMessage}</b>
          </Popover.Content>
        </Popover>
    );
    return (
        <OverlayTrigger
            placement="right"
            delay={{ show: 0, hide: 0 }}
            overlay={popover}
            trigger="hover"
        >
            <span style={{paddingLeft: '10px'}}><MDBIcon icon="info-circle" size="2x" /></span>
        </OverlayTrigger>
    );
}

function CourseCard(props) {

    const [isExpanded, setisExpanded] = useState(false);
    const iconBeforeCourse = isExpanded ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />;

    return (
        <Accordion>
            <AccordionHeaderWrapper>
                <Accordion.Toggle as={AccordionHeader} variant="link" eventKey="0" onClick={() => {
                    setisExpanded(!isExpanded);
                }}>
                    {iconBeforeCourse}&nbsp;&nbsp;
                    Course Introduction: <b>{props.course.title}</b>
                </Accordion.Toggle>
            </AccordionHeaderWrapper>
            <Accordion.Collapse eventKey="0">
                <AccordionBody><ContentPresenter markdownContent={props.course.introduction} /></AccordionBody>
            </Accordion.Collapse>
        </Accordion>
    );
}

CourseCard.propTypes = {
    course: PropTypes.object
}


function ChapterCard(props) {

    const [isExpanded, setisExpanded] = useState(false);
    const iconBeforeChapter = isExpanded ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />;

    return (
        <Accordion>
            <AccordionHeaderWrapper>
                <Accordion.Toggle as={AccordionHeader} variant="link" eventKey="0" onClick={() => {
                    setisExpanded(!isExpanded);
                }}>
                    {iconBeforeChapter}&nbsp;&nbsp;
                    Chapter Introduction: <b>{props.chapter.title}</b>
                </Accordion.Toggle>
            </AccordionHeaderWrapper>
            <Accordion.Collapse eventKey="0">
                <AccordionBody><ContentPresenter markdownContent={props.chapter.introduction} /></AccordionBody>
            </Accordion.Collapse>
        </Accordion>
    );
}

ChapterCard.propTypes = {
    chapter: PropTypes.object
}

function TopicCard(props) {

    const [isExpanded, setisExpanded] = useState(true);
    const iconBeforeTopic = isExpanded ? <MDBIcon icon="angle-down" /> : <MDBIcon icon="angle-right" />;

    return (
        <Accordion defaultActiveKey="0">
            <AccordionHeaderWrapper>
                <Accordion.Toggle as={AccordionHeader} variant="link" eventKey="0" onClick={() => {
                    setisExpanded(!isExpanded);
                }}>
                    {iconBeforeTopic}&nbsp;&nbsp;
                    Topic content: <b>{props.topic.title}</b>
                </Accordion.Toggle>
            </AccordionHeaderWrapper>
            <Accordion.Collapse eventKey="0">
                <AccordionBody><ContentPresenter markdownContent={props.topic.content} /></AccordionBody>
            </Accordion.Collapse>
        </Accordion>
    );
}

TopicCard.propTypes = {
    topic: PropTypes.object
}

CourseDetailsPanel.propTypes = {
    expandedCourses: PropTypes.array,
    history: PropTypes.object,
    flatCourseData: PropTypes.object
}

export default CourseDetailsPanel;