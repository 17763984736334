import React from 'react';
import { MDBIcon } from 'mdbreact';
import styled from 'styled-components';
import './footer.scss';

function Footer(props) {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h3><MDBIcon icon='envelope' /> Contact</h3>

                            <p className="footer-contact">

                                <a href="https://goo.gl/maps/aGoNZmGQc1opsboF7">
                                    <MapIconWrapper><MDBIcon icon='map-marker-alt' size='2x' /></MapIconWrapper>
                                    &nbsp;
                                </a>&nbsp; &nbsp;

                                186, Gulzar Bhavan<br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                Sector-3, Hiran Magri<br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                Udaipur (Rajasthan) <br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                Contact: 8003893172 <br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                Email:&nbsp;
                                <a
                                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=decentacademyudaipur@gmail.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    decentacademyudaipur@gmail.com
                                </a>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h3><MDBIcon icon='laptop-code' /> Facilities</h3>
                            <div style={{ color: '#007bff', padding: '10px' }}>
                                <div>Library</div>
                                <div>Conference Hall</div>
                                <div>Practical classes</div>
                                <div>Workstation</div>
                                <div>Coding classes</div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <h3><MDBIcon icon='camera' /> Socialize</h3>
                            <a href="https://www.linkedin.com/in/decent-academy-8a1192209/" target="_blank" rel="noreferrer">
                                <IconWrapper><MDBIcon icon='linkedin' size='2x' /></IconWrapper>
                            </a>
                            <a href="https://twitter.com/AcademyDecent" target="_blank" rel="noreferrer">
                                <IconWrapper><MDBIcon icon='twitter' size='2x' /></IconWrapper>
                            </a>
                            <a href="https://www.facebook.com/Decent-Academy-106042418231654" target="_blank" rel="noreferrer">
                                <IconWrapper><MDBIcon icon='facebook' size='2x' /></IconWrapper>
                            </a>
                            <a href="https://www.instagram.com/decent_academy/" target="_blank" rel="noreferrer">
                                <IconWrapper><MDBIcon icon='instagram' size='2x' /></IconWrapper>
                            </a>
                        </div>

                    </div>
                </div>
            </footer>
            <CopyrightWrapper>
                Copyright © 2021 Decent Academy of Computer Science | All rights reserved.
            </CopyrightWrapper>
        </>
    );
}

const CopyrightWrapper = styled.div`
    color: #fff;
    padding: 10px 0px 10px 0px;
    background-color: rgb(23, 23, 23);
    text-align: center;
`;

const MapIconWrapper = styled.span`
    color: red;
`;

const IconWrapper = styled.span`
    padding: 15px;
`;

export default Footer;
