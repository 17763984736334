

export function getSetUserActionObject(user) {
    return {
        type: 'users/setUser',
        payload: {
            user
        }
    };
}

export function getLogoutActionObject() {
    return {
        type: 'users/logout'
    };
}
