import { combineReducers } from 'redux'
import userReducer from "./users/reducer";
import coursesReducer from './courses/reducer';


const rootReducer = combineReducers({
    user: userReducer,
    courses: coursesReducer
})

export default rootReducer