import React, {useEffect, useState} from 'react';
import {PaddedDiv} from '../utils/jsxUtils';
import Select from 'react-select';
import {MDBInput} from 'mdbreact';
import CustomMDEditor from '../utils/mdEditor/CustomMDEditor';
import Button from 'react-bootstrap/Button';
import {NETWORK_CALL_STATUS} from '../utils/constants';
import _ from 'lodash';
import request from 'superagent';
import {validateNonEmptyString} from '../../login/utils';
import Toast from 'react-bootstrap/Toast';
import {setChapters, setCourses} from '../../stateManager/courses/action';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

function AddChapterEditor(props) {
  const [title, setTitle] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(undefined);
  const [introduction, setIntroduction] = useState('');

  const [keyCounter, setKeyCounter] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [publishStatus, setPublishStatus] = useState(
      NETWORK_CALL_STATUS.UNDEFINED,
  );
  const [pageLoadStatus, setPageLoadStatus] = useState(
      NETWORK_CALL_STATUS.UNDEFINED,
  );
  const [fieldValidationError, setFieldValidationError] = useState([]);

  useEffect(() => {
    if (_.isNil(props.courses.courses)) {
      const p = new Promise((resolve, reject) => {
        request.get('https://localhost:8089/courses/getAllCourses').
            end(function(error, result) {
              if (error) {
                setPageLoadStatus(NETWORK_CALL_STATUS.FAILED);
                reject(error);
              } else {
                setPageLoadStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
                resolve(result);
              }
            });
      });
      p.then(
          (result) => {
            const courses = result.body;
            props.setCourses(courses);
          },
          (error) => {
            console.log(error);
          },
      );
    } else {
      setPageLoadStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
    }
  }, []);

  if (_.isNil(props.courses?.courses) || pageLoadStatus ===
      NETWORK_CALL_STATUS.UNDEFINED) {
    return <React.Fragment/>;
  }

  const options = getCourseOptions();
  return (
      <PaddedDiv padding={10}>
        {getEditorContents()}
        {getToast()}
      </PaddedDiv>
  );

  function getEditorContents() {
    if (publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL) {
      return <React.Fragment/>;
    }
    return (
        <>
          <h4>Add Chapter</h4>
          <b>Select Course</b>
          <Select
              key={keyCounter.toString() + '-select'}
              options={options}
              value={selectedCourse}
              onChange={handleCourseChange}
          />
          <MDBInput
              outline
              containerClass="text-left"
              icon="book"
              value={title}
              label="Chapter Title"
              onChange={(event) => setTitle(event.target.value)}
          />

          <CustomMDEditor
              key={keyCounter.toString() + '-mdeditor'}
              title="Introduction"
              handleChange={(value) => setIntroduction(value)}
          />
          <Button variant="success" onClick={publishChapterHandler}>
            Publish Chapter
          </Button>
          <Button variant="danger" onClick={clearCourseHandler}>
            Clear Contents
          </Button>
        </>
    );
  }

  function handleCourseChange(selectedCourse) {
    setSelectedCourse(selectedCourse);
  }

  function getCourseOptions() {
    if (_.isEmpty(props.courses?.courses)) {
      return [];
    }
    const retValue = props.courses.courses.map((currentCourse) => {
      return {
        ...currentCourse,
        label: currentCourse.title,
        value: currentCourse.title,
      };
    });
    return _.orderBy(retValue, ['label'], ['asc']);
  }

  function publishChapterHandler() {
    setShowToast(true);
    setPublishStatus(NETWORK_CALL_STATUS.UNDEFINED);
    const currentFieldValidationErrors = getFieldValidationErrors();
    if (_.isEmpty(currentFieldValidationErrors)) {
      setFieldValidationError(currentFieldValidationErrors);
      setPublishStatus(NETWORK_CALL_STATUS.PENDING);

      const p = new Promise((resolve, reject) => {
        request.post('https://localhost:8089/chapters/createChapter').
            set('Content-Type', 'application/json').
            send({
              courseId: selectedCourse.courseId,
              title,
              description: introduction,
            }).
            end(function(error, result) {
              if (error) {
                setPublishStatus(NETWORK_CALL_STATUS.FAILED);
                reject(error);
              } else {
                setPublishStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
                props.setChapters(undefined);
                resolve(result);
              }
            });
      });
      p.then(
          (result) => {

          },
          (error) => {

          },
      );
    } else {
      setFieldValidationError(currentFieldValidationErrors);
    }
  }

  function getFieldValidationErrors() {
    const errorArray = [];
    if (_.isNil(selectedCourse)) {
      errorArray.push('Select a course');
    }
    if (!validateNonEmptyString(title)) {
      errorArray.push('Chapter title cannot be empty');
    }
    return errorArray;
  }

  function clearCourseHandler() {
    setTitle('');
    setSelectedCourse(undefined);
    setKeyCounter(keyCounter + 1);
    setShowToast(false);
  }

  function getToast() {
    if (!showToast) {
      return <React.Fragment/>;
    }

    let headerString;
    let toastBody;
    if (publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL) {
      headerString = 'Successfully published the chapter :)';
      toastBody = 'Enjoy!!!';
    } else if (
        publishStatus === NETWORK_CALL_STATUS.SUCCESSFUL_WITH_ERROR_MESSAGE ||
        publishStatus === NETWORK_CALL_STATUS.UNDEFINED
    ) {
      headerString = 'Error when publishing the chapter :(';
      const liArray = [];
      fieldValidationError.forEach((currentError) => {
        liArray.push(<li className="li-validation-error"
                         key={currentError}>{currentError}</li>);
      });
      toastBody = <ul>{liArray}</ul>;
    }

    return (
        <Toast onClose={() => setShowToast(false)}>
          <Toast.Header>
            <strong className="mr-auto">{headerString}</strong>
          </Toast.Header>
          <Toast.Body>{toastBody}</Toast.Body>
        </Toast>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCourses: (courses) => dispatch(setCourses(courses)),
    setChapters: (chapters) => dispatch(setChapters(chapters)),
  };
};

AddChapterEditor.propTypes = {
  setCourses: PropTypes.func,
  setChapters: PropTypes.func,
  courses: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddChapterEditor);