import React, {useState} from 'react';
import {ProgressBar} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

function CustomProgressBar(props) {
  if (props.show) {
    const [percent, setPercent] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    const updateAfter = 50; // in ms
    const count = props.time / updateAfter;
    const percentToBeAdded = props.time / 100;

    setTimeout(() => {
      if (currentCount <= count) {
        setCurrentCount(currentCount + 1);
        setPercent(Math.floor(currentCount * 100 / count));
      }
    }, updateAfter);
    return (
        <div>
          <ProgressBar
              animated
              variant="success"
              now={percent}
              label={percent + '%'}/>
        </div>
    );
  }
  return <React.Fragment />;
}

CustomProgressBar.propTypes = {
  show: PropTypes.bool,
  time: PropTypes.number
}

export default CustomProgressBar;