import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import request from 'superagent';
import {MDBCard, MDBCardTitle, MDBContainer, MDBInput} from 'mdbreact';
import CustomProgressBar from '../components/utils/CustomProgressBar';
import Toast from 'react-bootstrap/Toast';
import _ from 'lodash';
import {NETWORK_CALL_STATUS} from '../components/utils/constants';
import AcademyName from '../components/utils/AcademyName';
import './signup.scss';
import {
  FormOuterWrapper,
  validateEmail,
  validateNonEmptyString,
  ValidationToast,
} from './utils';
import {PaddedDiv} from '../components/utils/jsxUtils';
import {isResponseSuccessfulWithSuccessMessage} from '../components/utils/responseUtils';
import {saveUserNameAndPassword} from '../commons/localStorageUtils';

function SignUp(props) {

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [signupStatus, setSignupStatus] = useState(
      NETWORK_CALL_STATUS.UNDEFINED);

  const [showToast, setShowToast] = useState(true);

  const [fieldValidationError, setFieldValidationError] = useState(undefined);

  function getFieldValidationErrors() {
    const errorArray = [];
    if (!validateNonEmptyString(firstName)) {
      errorArray.push('First name cannot be empty');
    }
    if (!validateNonEmptyString(lastName)) {
      errorArray.push('Last name cannot be empty');
    }
    if (!validateNonEmptyString(userName)) {
      errorArray.push('Username cannot be empty');
    }
    if (!validateNonEmptyString(password)) {
      errorArray.push('Password cannot be empty');
    }
    if (!validateNonEmptyString(email)) {
      errorArray.push('Email cannot be empty');
    } else if (!validateEmail(email)) {
      errorArray.push('Invalid email entered');
    }

    return errorArray;
  }

  function onSubmit(event) {
    setShowToast(true);
    setSignupStatus(NETWORK_CALL_STATUS.UNDEFINED);
    const currentFieldValidationErrors = getFieldValidationErrors();
    if (_.isEmpty(currentFieldValidationErrors)) {
      setFieldValidationError(currentFieldValidationErrors);
      setSignupStatus(NETWORK_CALL_STATUS.PENDING);
      const p = new Promise((resolve, reject) => {
        request.post('https://localhost:8089/users/createUser').
            set('Content-Type', 'application/json').
            send({
              firstName,
              lastName,
              password,
              userName,
              email,
            }).
            end(function(error, result) {
              if (error) {
                setSignupStatus(NETWORK_CALL_STATUS.FAILED);
                reject(error);
              } else {
                setSignupStatus(NETWORK_CALL_STATUS.SUCCESSFUL);
                resolve(result);
              }
            });
      });
      p.then(
          (result) => {
            if (isResponseSuccessfulWithSuccessMessage(result)) {
              setSignupStatus(
                  NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE
              );
              saveUserNameAndPassword(userName, password);
            } else {
              setSignupStatus(
                  NETWORK_CALL_STATUS.SUCCESSFUL_WITH_ERROR_MESSAGE);
              setFieldValidationError([...result.body.errorMessages]);
            }
          },
          (error) => {

          },
      );
    } else {
      setFieldValidationError(currentFieldValidationErrors);
    }
  }

  function setFieldBasedOnEventValue(event, setField) {
    setField(event.target.value);
  }

  return (
      <div className="signup-form-wrapper">
        <MDBContainer className="signup-mdb-container">
          <MDBCard className="card-body signup-mdb-card"
                   style={{width: '32rem', marginTop: '1rem'}}>
            <MDBCardTitle>Signup with Decent</MDBCardTitle>
            <FormOuterWrapper>
              {(signupStatus !== NETWORK_CALL_STATUS.SUCCESSFUL && signupStatus !== NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE) &&
              getFormActionItems()}
              {showToast && signupStatus === NETWORK_CALL_STATUS.PENDING &&
              <CustomProgressBar show time={100}/>}
              {showToast && (signupStatus === NETWORK_CALL_STATUS.SUCCESSFUL ||
                  signupStatus ===
                  NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE) &&
              getToast()}
              {showToast && !_.isEmpty(fieldValidationError) &&
              <ValidationToast
                  setShowToast={setShowToast}
                  fieldValidationError={fieldValidationError}
              />}
              {signupStatus === NETWORK_CALL_STATUS.FAILED && getToast()}
            </FormOuterWrapper>
          </MDBCard>
        </MDBContainer>
        <AcademyName/>
      </div>
  );

  function getFormActionItems() {
    return (
        <>
          <MDBInput
              outline
              className={'signup-input-field' +
              (!_.isNil(fieldValidationError) &&
              !validateNonEmptyString(firstName) ?
                  ' error-input-field' :
                  '')}
              containerClass="text-left"
              icon="user-alt"
              value={firstName}
              label="First name"
              onChange={(event) => setFieldBasedOnEventValue(event,
                  setFirstName)}
          />
          <MDBInput
              outline
              className={'signup-input-field' +
              (!_.isNil(fieldValidationError) &&
              !validateNonEmptyString(lastName) ?
                  ' error-input-field' :
                  '')}
              containerClass="text-left"
              icon="user-alt"
              value={lastName}
              label="Last name"
              onChange={(event) => setFieldBasedOnEventValue(event,
                  setLastName)}
          />
          <MDBInput
              outline
              className={'signup-input-field' +
              ((!_.isNil(fieldValidationError) &&
                  (!validateNonEmptyString(email) ||
                      !validateEmail(email))) ? ' error-input-field' : '')}
              containerClass="text-left"
              value={email}
              icon="mail-bulk"
              label="Email address"
              onChange={(event) => setFieldBasedOnEventValue(event,
                  setEmail)}
          />
          <MDBInput
              outline
              className={'signup-input-field' +
              (!_.isNil(fieldValidationError) &&
              !validateNonEmptyString(userName) ?
                  ' error-input-field' :
                  '')}
              containerClass="text-left"
              icon="user"
              value={userName}
              label="User name"
              onChange={(event) => setFieldBasedOnEventValue(event,
                  setUserName)}
          />
          <MDBInput
              outline
              className={'signup-input-field' +
              (!_.isNil(fieldValidationError) &&
              !validateNonEmptyString(password) ?
                  ' error-input-field' :
                  '')}
              containerClass="text-left"
              icon="user-secret"
              type="password"
              label="Password"
              value={password}
              onChange={(event) => setFieldBasedOnEventValue(event,
                  setPassword)}
          />
          <PaddedDiv>
            <Button variant="primary" onClick={onSubmit}>SIGNUP</Button>
          </PaddedDiv>
        </>
    );
  }

  function getValidationToast() {
    const liArray = [];
    fieldValidationError.forEach((currentError) => {
      liArray.push(<li className="li-validation-error"
                       key={currentError}>{currentError}</li>);
    });
    return (
        <Toast onClose={() => setShowToast(false)}>
          <Toast.Header>
            <strong className="mr-auto">Validation error occurred!</strong>
            <small>:(</small>
          </Toast.Header>
          <Toast.Body>
            <ul>{liArray}</ul>
          </Toast.Body>
        </Toast>
    );
  }

  function getToast() {
    if (signupStatus === NETWORK_CALL_STATUS.FAILED) {
      return (
          <Toast onClose={() => setShowToast(false)}>
            <Toast.Header>
              <strong className="mr-auto">Signup failed!&nbsp;&nbsp;</strong>
              <small>:(</small>
            </Toast.Header>
            <Toast.Body>Signup again!</Toast.Body>
          </Toast>
      );
    }
    if (signupStatus === NETWORK_CALL_STATUS.SUCCESSFUL_WITH_SUCCESS_MESSAGE) {
      return (
          <Toast onClose={() => setShowToast(false)}>
            <Toast.Header>
              <strong className="mr-auto">Signup
                Successful!&nbsp;&nbsp;</strong>
              <small>:)</small>
            </Toast.Header>
            <Toast.Body>Click here to login</Toast.Body>
          </Toast>
      );
    }
  }

}

export default SignUp;
