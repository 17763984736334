import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/*
  choose any other syntax highlighter from here:
  https://cdnjs.com/libraries/highlight.js
 */
ReactDOM.render(
    <React.StrictMode>
      <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/8.4/styles/default.min.css"/>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/highlight.js/latest/styles/github.min.css" />
      <link rel="stylesheet" type="text/plain" href="https://unpkg.com/easymde/dist/easymde.min.css" />
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.2/css/all.css"></link>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.2/css/v4-shims.css"></link>
      <App/>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
