import React from 'react';
import './App.css';
import { createStore } from 'redux'

import BasicRoutes from "./routes/BasicRoutes";
import rootReducer from "./stateManager/reducer";
import { Provider } from "react-redux";

function App() {
    const store = createStore(
        rootReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    return (
        <Provider store={store}>
            <div className="App">
                <BasicRoutes />
            </div>
        </Provider>
    );
}

export default App;
