import React from 'react';
import PropTypes from 'prop-types';
import NavigationBar from "./NavigationBar";
import Footer from './utils/footer/Footer';


function BaseComponent(props) {
    return (
        <div>
            <NavigationBar />
            <div style={{ width: '100%', height: '92%', zIndex: '5', marginTop: '70px' }}>
                <div style={{ minHeight: '584px' }}>
                    {props.component}
                </div>
                <Footer />
            </div>
        </div>
    );
}

BaseComponent.propTypes = {
    component: PropTypes.node
};

export default BaseComponent;