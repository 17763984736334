import hljs from 'highlight.js';

export function setMarkedOptions(markedObject) {
    markedObject.setOptions({
        gfm: true,
        tables: true,
        breaks: true,
        pedantic: false,
        sanitize: true,
        smartLists: true,
        smartypants: true,
        langPrefix: 'hljs language-',
        highlight: function (code) {
            return hljs.highlightAuto(code, ['html', 'javascript', 'java']).value;
        }
    });
    hljs.initHighlightingOnLoad();
}
