import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import AddCourseEditor from './AddCourseEditor';
import AddChapterEditor from './AddChapterEditor';
import AddTopicEditor from './AddTopicEditor';

function AddCourseComponent() {
  const [componentToShow, setComponentToShow] = useState(<AddCourseEditor/>);
  const [addCourseButtonVariant, setAddCourseButtonVariant] =
      useState('primary');
  const [addChapterButtonVariant, setAddChapterButtonVariant] =
      useState('outline-primary');
  const [addTopicButtonVariant, setAddTopicButtonVariant] =
      useState('outline-primary');

  return (
      <>
        <Button variant={addCourseButtonVariant} onClick={addCourseHandler}>
          Add Course
        </Button>
        <Button variant={addChapterButtonVariant} onClick={addChapterHandler}>
          Add Chapter
        </Button>
        <Button variant={addTopicButtonVariant} onClick={addTopicHandler}>
          Add Topic
        </Button>
        {componentToShow}
      </>
  );

  function addCourseHandler() {
    setComponentToShow(<AddCourseEditor/>);
    setAddCourseButtonVariant('primary');
    setAddChapterButtonVariant('outline-primary');
    setAddTopicButtonVariant('outline-primary');
  }

  function addChapterHandler() {
    setComponentToShow(<AddChapterEditor/>);
    setAddChapterButtonVariant('primary');
    setAddCourseButtonVariant('outline-primary');
    setAddTopicButtonVariant('outline-primary');
  }

  function addTopicHandler() {
    setComponentToShow(<AddTopicEditor/>);
    setAddTopicButtonVariant('primary');
    setAddChapterButtonVariant('outline-primary');
    setAddCourseButtonVariant('outline-primary');
  }

}

export default AddCourseComponent;